<div class="workflow-drawer-details">
  <div class="workflow-drawer-details__section">
    <div class="workflow-drawer-details__section-title">
      {{ 'workflowDrawer.detailsTab.type' | translate }}
    </div>

    <div class="workflow-drawer-details__section-content">
      {{ workflow().type }}
    </div>
  </div>

  <div class="workflow-drawer-details__section">
    <div class="workflow-drawer-details__section-title">
      {{ 'workflowDrawer.detailsTab.revision' | translate }}
    </div>

    <div class="workflow-drawer-details__section-content cc-workflow-revision">v{{ workflow().version }}</div>
  </div>

  <div class="workflow-drawer-details__section">
    <div class="workflow-drawer-details__section-title">
      {{ 'workflowDrawer.detailsTab.whoHasAccess' | translate }}

      <i
        [ccTooltip]="'workflowDrawer.detailsTab.whoHasAccessTooltip' | translate"
        class="icon-info-fill"
      ></i>
    </div>

    <div class="workflow-drawer-details__section-content">
      {{ usersWithAccess() }}
    </div>
  </div>

  <div class="workflow-drawer-details__section">
    <div class="workflow-drawer-details__section-title">
      {{ 'workflowDrawer.detailsTab.ownedBy' | translate }}
    </div>

    <div class="workflow-drawer-details__section-content cc-workflow-owner">
      <cc-user-avatar
        class="cc-workflow-owner__avatar"
        [src]="owner().avatarUrl"
        [name]="[owner().firstName, owner().lastName]"
        [id]="owner().id"
        [size]="24"
      />

      <span class="cc-workflow-owner__name"> {{ owner().firstName }} {{ owner().lastName }} </span>
    </div>
  </div>

  <div class="workflow-drawer-details__section">
    <div class="workflow-drawer-details__section-title">
      {{ 'workflowDrawer.detailsTab.created' | translate }}
    </div>

    <div class="workflow-drawer-details__section-content">
      {{
        workflow().createdAt
          | ccDateFormat
            : {
                date: 'short',
              }
      }}
    </div>
  </div>

  <div class="workflow-drawer-details__section">
    <div class="workflow-drawer-details__section-title">
      {{ 'workflowDrawer.detailsTab.modified' | translate }}
    </div>

    <div class="workflow-drawer-details__section-content">
      {{
        workflow().modifiedAt
          | ccDateFormat
            : {
                date: 'short',
              }
      }}
    </div>
  </div>

  <div class="workflow-drawer-details__section">
    <div class="workflow-drawer-details__section-title">
      {{ 'workflowDrawer.detailsTab.description' | translate }}
    </div>

    <div class="workflow-drawer-details__section-content">
      {{ workflow().description }}
    </div>
  </div>
</div>
