import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, Renderer2, type OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar } from 'ngx-scrollbar';

import { WorkflowType } from '@clover/workflows-v2/state/workflows-state.model';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import {
  createAudienceRequiredValidator,
  TaskAssignmentAssigneePickerComponent,
} from '@design/misc/assignee-picker/assignee-picker.component';
import { TaskAssignmentWorkflowPickerComponent } from '@design/misc/workflow-picker/workflow-picker.component';

import type { TaskAudience } from '../task-assignment.model';
import type { CompanyTaskAssignmentConfig } from '../task-assignment.service';

export interface CompanyTaskAssignmentDialogData extends CompanyTaskAssignmentConfig {
  companyId: number; // Overriding optional companyId from TaskAssignmentFlowConfig
}

export interface CompanyTaskAssignmentDialogResult {
  companyId: number;
  workflowId: number;
  audience: TaskAudience;
}

@Component({
  standalone: true,
  imports: [
    ButtonComponent,
    NgScrollbar,
    TaskAssignmentWorkflowPickerComponent,
    TaskAssignmentAssigneePickerComponent,
    TranslateModule,
  ],
  templateUrl: './company-dialog.component.html',
  styleUrl: './company-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyTaskAssignmentDialogComponent implements OnInit {
  protected readonly form = new FormGroup({
    workflowId: new FormControl<number>(null, Validators.required),
    audience: new FormControl<TaskAudience>(null, createAudienceRequiredValidator()),
  });

  protected readonly dialogData: CompanyTaskAssignmentDialogData = inject(DIALOG_DATA);
  protected readonly dialogRef: DialogRef<CompanyTaskAssignmentDialogResult | undefined> = inject(
    DialogRef<CompanyTaskAssignmentDialogResult | undefined>,
  );

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly WorkflowType = WorkflowType;

  private readonly renderer = inject(Renderer2);

  get selectedWorkflowId(): number {
    return this.form.get('workflowId').value;
  }

  get selectedAudience(): TaskAudience {
    return this.form.get('audience').value;
  }

  ngOnInit(): void {
    this.form.get('workflowId').setValue(this.dialogData?.predefinedWorkflowId);
    this.form.get('audience').setValue({
      contactIds: [],
      departmentIds: [],
      ...(this.dialogData?.predefinedAudience || {}),
    });
  }

  assignTask(): void {
    if (this.form.invalid) return;

    this.dialogRef.close({
      companyId: this.dialogData.companyId,
      workflowId: this.form.get('workflowId').value,
      audience: this.form.get('audience').value,
    });
  }

  handleWorkflowChange(workflowId: number): void {
    this.form.get('workflowId').setValue(workflowId);
  }

  handleAudienceChange(audience: TaskAudience): void {
    this.form.get('audience').setValue(audience);
  }

  handleChildDialogOpened(): void {
    this.dialogRef.disableClose = true;

    const overlayRef = this.dialogRef.overlayRef;
    const { hostElement, backdropElement } = overlayRef;
    this.renderer.addClass(hostElement, 'cc-overlay-hidden');
    this.renderer.addClass(backdropElement, 'cc-overlay-hidden');
  }

  handleChildDialogClosed(): void {
    this.dialogRef.disableClose = false;

    const overlayRef = this.dialogRef.overlayRef;
    const { hostElement, backdropElement } = overlayRef;
    this.renderer.removeClass(hostElement, 'cc-overlay-hidden');
    this.renderer.removeClass(backdropElement, 'cc-overlay-hidden');
  }
}
