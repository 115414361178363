import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { DateFormatPipe } from '@clover/core/pipes/date-format.pipe';
import { WorkflowAccessType, type WorkflowDetails } from '@clover/workflows-v2/state/workflows-state.model';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { UserAvatarComponent } from '../../../../../user-avatar/user-logo.component';

@Component({
  selector: 'cc-workflow-drawer-details',
  standalone: true,
  imports: [DateFormatPipe, TranslateModule, TooltipDirective, TranslateModule, UserAvatarComponent],
  templateUrl: './workflow-drawer-details.component.html',
  styleUrl: './workflow-drawer-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowDrawerDetailsComponent {
  workflow = input.required<WorkflowDetails>();

  private readonly translate = inject(TranslateService);

  owner = computed(() => this.workflow().createdBy);

  usersWithAccess = computed(() => {
    const { access } = this.workflow();

    if (access.type === WorkflowAccessType.All)
      return this.translate.instant('workflowDrawer.detailsTab.allTeamsHaveAccess');

    if (access.type === WorkflowAccessType.Teams && access.teams.length > 0)
      return access.teams.map((team) => team.name).join(', ');

    return this.translate.instant('workflowDrawer.detailsTab.noTeamsHaveAccess');
  });
}
