import type { ContactCompany } from '@clover/conversations-v4/conversation/state/contacts/contacts.model';
export enum TaskAssignmentType {
  Company = 'company',
  Product = 'product',
}

export enum TaskAudienceType {
  Contacts = 'contacts',
  Departments = 'departments',
}

export interface TaskAudienceContact {
  id: number;
  avatarUrl: string | undefined;
  firstName: string;
  lastName: string;
  email: string;
  company: ContactCompany;
  active: boolean;
}

export interface TaskAudienceDepartment {
  id: string;
  title: string;
}
export interface TaskAudience {
  contactIds: number[];
  departmentIds: string[];
}
