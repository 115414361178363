import { Dialog } from '@angular/cdk/dialog';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  catchError,
  map,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  type Observable,
  type Subscription,
} from 'rxjs';

import { CdkPortalService } from '@clover/core/services/cdk-portal.service';
import { HttpService } from '@clover/core/services/http.service';
import {
  RoutePickerDialogComponent,
  RoutePickerDialogResult,
  type RoutePickerDialogData,
} from '@design/overlays/route-picker-dialog/route-picker-dialog.component';
import { ToastType } from '@design/overlays/toast/toast';

import type { Task } from '../tasks.model';
import { mapTask } from '../tasks.service';
import {
  CompanyTaskAssignmentDialogComponent,
  type CompanyTaskAssignmentDialogData,
  type CompanyTaskAssignmentDialogResult,
} from './company-dialog/company-dialog.component';
import {
  ProductTaskAssignmentDialogComponent,
  type ProductTaskAssignmentDialogData,
  type ProductTaskAssignmentDialogResult,
} from './product-dialog/product-dialog.component';
import { TempProductsService, type Product } from './product-dialog/vendor-association-dialog/temp-products.service';
import {
  VendorAssociationDialogComponent,
  type VendorAssociationDialogData,
  type VendorAssociationDialogResult,
  type VendorAssociationResult,
} from './product-dialog/vendor-association-dialog/vendor-association-dialog.component';
import { TaskAssignmentType, type TaskAudience } from './task-assignment.model';

// TODO (Oleksandr D.): Fix types
interface BaseTaskAssignmentFlowConfig {
  taskType?: TaskAssignmentType;
  predefinedWorkflowId?: number;
  predefinedAudience?: Partial<TaskAudience>;
}

export type CompanyTaskAssignmentConfig = BaseTaskAssignmentFlowConfig & {
  taskType: TaskAssignmentType.Company;
  companyId?: number;
};

export type ProductTaskAssignmentConfig = BaseTaskAssignmentFlowConfig & {
  taskType: TaskAssignmentType.Product;
  productId?: number;
  vendorId?: number;
};

export type UndeterminedTaskAssignmentConfig = BaseTaskAssignmentFlowConfig &
  Omit<CompanyTaskAssignmentConfig, 'taskType'> &
  Omit<ProductTaskAssignmentConfig, 'taskType'>;

export type TaskAssignmentFlowConfig =
  | CompanyTaskAssignmentConfig
  | ProductTaskAssignmentConfig
  | UndeterminedTaskAssignmentConfig;

@Injectable({
  providedIn: 'root',
})
export class TaskAssignmentService {
  private readonly dialog = inject(Dialog);
  private readonly portalService = inject(CdkPortalService);
  private readonly http = inject(HttpService);
  private readonly productsService = inject(TempProductsService);
  private readonly translate = inject(TranslateService);

  private readonly cancel$ = new Subject<void>();

  startTaskAssignmentFlow(config: TaskAssignmentFlowConfig): Subscription {
    return of(true)
      .pipe(
        takeUntil(this.cancel$),
        switchMap(() => {
          if (config.taskType) return of(config.taskType);
          return this.promptForTaskAssignmentType();
        }),
        switchMap((taskType: TaskAssignmentType | undefined) => {
          switch (taskType) {
            case TaskAssignmentType.Company:
              return this.startCompanyTaskAssignmentFlow(config as CompanyTaskAssignmentConfig);
            case TaskAssignmentType.Product:
              return this.startProductTaskAssignmentFlow(config as ProductTaskAssignmentConfig);
            default:
              return this.cancelFlow();
          }
        }),
      )
      .subscribe();
  }

  private promptForTaskAssignmentType(): Observable<TaskAssignmentType | undefined> {
    const dialog = this.dialog.open<RoutePickerDialogResult, RoutePickerDialogData>(RoutePickerDialogComponent, {
      data: {
        title: this.translate.instant('taskAssignment.typeSelectorModal.title'),
        description: this.translate.instant('taskAssignment.typeSelectorModal.description'),
        options: [
          {
            id: TaskAssignmentType.Company,
            icon: 'icon-company-fill',
            title: this.translate.instant('taskAssignment.typeSelectorModal.options.company.title'),
            description: this.translate.instant('taskAssignment.typeSelectorModal.options.company.description'),
          },
          {
            id: TaskAssignmentType.Product,
            icon: 'icon-products-fill',
            title: this.translate.instant('taskAssignment.typeSelectorModal.options.product.title'),
            description: this.translate.instant('taskAssignment.typeSelectorModal.options.product.description'),
          },
        ],
      },
    });

    return dialog.closed.pipe(
      take(1),
      map((result: RoutePickerDialogResult) => {
        return result as TaskAssignmentType;
      }),
    );
  }

  private startCompanyTaskAssignmentFlow(config: CompanyTaskAssignmentConfig): Observable<Task | undefined> {
    return of(true).pipe(
      switchMap(() => {
        if (config.companyId) return of(config.companyId);

        // TODO (Oleksandr D.): Implement company selection flow
        throw new Error('Company selection flow is not implemented yet');
      }),
      switchMap((companyId: number) => {
        if (!companyId) return this.cancelFlow();

        const dialog = this.dialog.open<CompanyTaskAssignmentDialogResult, CompanyTaskAssignmentDialogData>(
          CompanyTaskAssignmentDialogComponent,
          {
            data: {
              ...config,
              companyId,
            },
          },
        );

        return dialog.closed.pipe(take(1));
      }),
      switchMap((result: CompanyTaskAssignmentDialogResult | undefined) => {
        if (!result) return this.cancelFlow();

        return this.http
          .postV2('/api/tasks/assign/company', {
            companyId: result.companyId,
            workflowId: result.workflowId,
            audience: {
              contactIds: result.audience.contactIds || [],
              departmentIds: result.audience.departmentIds || [],
            },
          })
          .pipe(
            tap(() => {
              this.portalService.presentToast(
                this.translate.instant('taskAssignment.toasts.taskAssigned'),
                ToastType.Success,
              );
            }),
            map(mapTask),
            catchError(() => {
              this.portalService.presentToast(
                this.translate.instant('taskAssignment.toasts.failedToAssignTask'),
                ToastType.Error,
              );
              return of(undefined);
            }),
          );
      }),
    );
  }

  private startProductTaskAssignmentFlow(config: ProductTaskAssignmentConfig): Observable<Task | undefined> {
    return of(true).pipe(
      switchMap(() => {
        if (config.productId) return of(config.productId);

        // TODO (Oleksandr D.): Implement product selection flow
        alert('Product selection flow is not implemented yet');
        return this.cancelFlow();
      }),
      switchMap((productId: number) => this.startVendorAssociationFlow(productId)),
      switchMap((vendorAssociationResult: VendorAssociationResult) => {
        if (!vendorAssociationResult) return this.cancelFlow();
        const { productId, vendorId } = vendorAssociationResult;

        const dialog = this.dialog.open<ProductTaskAssignmentDialogResult, ProductTaskAssignmentDialogData>(
          ProductTaskAssignmentDialogComponent,
          {
            data: {
              ...config,
              productId,
              vendorId,
            },
          },
        );
        return dialog.closed.pipe(take(1));
      }),
      switchMap((result: ProductTaskAssignmentDialogResult | undefined) => {
        if (!result) return this.cancelFlow();

        return this.http
          .postV2('/api/tasks/assign/product', {
            productId: result.productId,
            companyId: result.vendorId,
            workflowId: result.workflowId,
            audience: {
              contactIds: result.audience.contactIds || [],
              departmentIds: result.audience.departmentIds || [],
            },
          })
          .pipe(
            tap(() => {
              this.portalService.presentToast(
                this.translate.instant('taskAssignment.toasts.taskAssigned'),
                ToastType.Success,
              );
            }),
            map(mapTask),
            catchError(() => {
              this.portalService.presentToast(
                this.translate.instant('taskAssignment.toasts.failedToAssignTask'),
                ToastType.Error,
              );
              return of(undefined);
            }),
          );
      }),
    );
  }

  private startVendorAssociationFlow(productId: number): Observable<VendorAssociationResult | undefined> {
    if (!productId) return this.cancelFlow();

    return this.productsService.getProduct(productId).pipe(
      switchMap((product) => {
        if (!product) return this.cancelFlow();
        return of(product);
      }),
      map((product) => {
        const { vendor: { companyId: vendorId = undefined } = {} } = product;
        if (!vendorId) return { product, vendorId: undefined };

        return { product, vendorId };
      }),
      switchMap(({ product, vendorId }: { product: Product; vendorId: number | undefined; vendorExists: boolean }) => {
        if (!vendorId) return of({ product, vendorId, vendorExists: false });

        return this.productsService
          .getVendor(vendorId)
          .pipe(map((vendor) => ({ product, vendorId, vendorExists: !!vendor })));
      }),
      switchMap(
        ({
          product,
          vendorId,
          vendorExists,
        }: {
          product: Product;
          vendorId: number | undefined;
          vendorExists: boolean;
        }) => {
          if (vendorId && vendorExists)
            return of({
              productId,
              vendorId,
            });

          const dialog = this.dialog.open<VendorAssociationDialogResult, VendorAssociationDialogData>(
            VendorAssociationDialogComponent,
            { data: product },
          );
          return dialog.closed.pipe(
            take(1),
            switchMap((result: VendorAssociationResult | undefined) => {
              if (!result) return this.cancelFlow();
              return of(result);
            }),
          );
        },
      ),
    );
  }

  private cancelFlow(): Observable<undefined> {
    this.cancel$.next();
    return of(undefined);
  }
}
